import { default as indexpEBoOYqhcAMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/browse/[category]/index.vue?macro=true";
import { default as indexKJX7zJti5OMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/browse/components/index.ts?macro=true";
import { default as PageFilters7EXUmxadLEMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/browse/components/PageFilters.vue?macro=true";
import { default as SectionGenreswzm8LJdYShMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/browse/components/SectionGenres.vue?macro=true";
import { default as SectionMoviespGdNefLpopMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/browse/components/SectionMovies.vue?macro=true";
import { default as SectionPopularjOD8BIKPMAMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/browse/components/SectionPopular.vue?macro=true";
import { default as SectionSeries8VEIpXquYjMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/browse/components/SectionSeries.vue?macro=true";
import { default as SkeletonFilterscOCnajaGZcMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/browse/components/SkeletonFilters.vue?macro=true";
import { default as SkeletonGenresEYlGMuxOjRMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/browse/components/SkeletonGenres.vue?macro=true";
import { default as _91slug_93K0eryEv75UMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/browse/genre/[slug].vue?macro=true";
import { default as indexDRhIlrEjtgMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/browse/index.vue?macro=true";
import { default as _91slug_93TB01t90jhFMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/collections/[slug].vue?macro=true";
import { default as indexOCGiILeRMdMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/collections/index.vue?macro=true";
import { default as copyrighthsBR0zxlvsMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/copyright.vue?macro=true";
import { default as _91id_93QOnOvcOsdJMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/crew/[id].vue?macro=true";
import { default as indexQhF6QkNS5eMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/faq/index.vue?macro=true";
import { default as allhgAdSHXT9CMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/favorites/all.vue?macro=true";
import { default as SectionBoughtqO0I22NUQuMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/favorites/components/SectionBought.vue?macro=true";
import { default as SectionContinue9uoaV1rYXwMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/favorites/components/SectionContinue.vue?macro=true";
import { default as SectionFavoritesvNfZnISvamMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/favorites/components/SectionFavorites.vue?macro=true";
import { default as SectionViewedBejsbovqn1Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/favorites/components/SectionViewed.vue?macro=true";
import { default as continueZmIfFqYmsHMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/favorites/continue.vue?macro=true";
import { default as indexgnThGDw0v0Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/favorites/index.vue?macro=true";
import { default as purchased0DiJNAulemMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/favorites/purchased.vue?macro=true";
import { default as viewedaMMmCKUy4WMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/favorites/viewed.vue?macro=true";
import { default as holder_45policydOj1mwaH3bMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/holder-policy.vue?macro=true";
import { default as SectionAdvantagesujypp75ip7Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/components/SectionAdvantages.vue?macro=true";
import { default as SectionCollectionse8f1PsruHXMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/components/SectionCollections.vue?macro=true";
import { default as SectionContinueEvbW19D3yyMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/components/SectionContinue.vue?macro=true";
import { default as SectionFilms4FaolcivIbMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/components/SectionFilms.vue?macro=true";
import { default as SectionGenresfFE80N93xKMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/components/SectionGenres.vue?macro=true";
import { default as SectionHeader95fdXRPjJlMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/components/SectionHeader.vue?macro=true";
import { default as SectionPopularRhksBagpMSMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/components/SectionPopular.vue?macro=true";
import { default as SectionPremiers3oEdewpnmtMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/components/SectionPremiers.vue?macro=true";
import { default as SectionSeriesL9lVte3a1AMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/components/SectionSeries.vue?macro=true";
import { default as SectionSubscriptionsh3RFKJHgG8Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/components/SectionSubscriptions.vue?macro=true";
import { default as SectionSummarizetpUWNaSngXMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/components/SectionSummarize.vue?macro=true";
import { default as SectionUpcoming7ejKUCTLDGMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/components/SectionUpcoming.vue?macro=true";
import { default as IndexQ7XRLMNVvtMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/Index.vue?macro=true";
import { default as _91slug_93UMikN9M9Y2Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/install/[slug].vue?macro=true";
import { default as InfoInstallwmYEKjo63PMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/install/components/InfoInstall.vue?macro=true";
import { default as TitleInstallrchKhUp3LZMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/install/components/TitleInstall.vue?macro=true";
import { default as typesmIcWzfWJQbMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/install/components/types.ts?macro=true";
import { default as useInstallp8SryDsU9LMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/install/components/useInstall.ts?macro=true";
import { default as filmcrewqtQG6G2WIeMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/[slug]/filmcrew.vue?macro=true";
import { default as indexwji5ywOxmIMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/[slug]/index.vue?macro=true";
import { default as MovieCollectionsWqPKQWtCBmMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/components/MovieCollections.vue?macro=true";
import { default as MovieCrewCmXnI4kbv6Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/components/MovieCrew.vue?macro=true";
import { default as MovieDescriptionXHKvX0hkv2Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/components/MovieDescription.vue?macro=true";
import { default as MovieHeaderTfXOmo4nXjMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/components/MovieHeader.vue?macro=true";
import { default as MovieHeaderNoticeSdCLGyb428Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/components/MovieHeaderNotice.vue?macro=true";
import { default as MovieHeaderSkeletonk5Vm83om24Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/components/MovieHeaderSkeleton.vue?macro=true";
import { default as MoviePlayButton8ldAzixSHFMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/components/MoviePlayButton.vue?macro=true";
import { default as MovieRelatedKo2dxw88dzMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/components/MovieRelated.vue?macro=true";
import { default as MovieSeriesNB1FgO3TNUMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/components/MovieSeries.vue?macro=true";
import { default as MovieTrailersY3KVoC4DYyMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/components/MovieTrailers.vue?macro=true";
import { default as MovieWatchOptionsR31JtmLdiDMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/components/MovieWatchOptions.vue?macro=true";
import { default as _91slug_933Ut6RG1zFqMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/news/[slug].vue?macro=true";
import { default as indexIYHRUzX960Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/news/index.vue?macro=true";
import { default as novelty_45filmsIPsWpashJAMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/novelty-films.vue?macro=true";
import { default as novelty_45serieseV6pedPt21Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/novelty-series.vue?macro=true";
import { default as policy_45agreementDzmSa5TEOUMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/policy-agreement.vue?macro=true";
import { default as policy_45copyrightEeXYpJS7tgMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/policy-copyright.vue?macro=true";
import { default as policy_45holderhIuH46to1VMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/policy-holder.vue?macro=true";
import { default as policy_45privatepseqUB84MKMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/policy-private.vue?macro=true";
import { default as policy_45userXJt2XEBWS5Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/policy-user.vue?macro=true";
import { default as premierss5QCNcSHLPMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/premiers.vue?macro=true";
import { default as private_45policy2IblkrXEyzMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/private-policy.vue?macro=true";
import { default as indexsrGRZsZy6DMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/bonuses/index.vue?macro=true";
import { default as VChangeRecurrentCardModals2DBlYypRXMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/modal/VChangeRecurrentCardModal.vue?macro=true";
import { default as VEditProfilejFDrNwZ7cZMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/modal/VEditProfile.vue?macro=true";
import { default as VRejectSubscribeModal3PX1FSUWc7Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/modal/VRejectSubscribeModal.vue?macro=true";
import { default as VResumeSubscriptionSuccessModalG4K9UOXOCbMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/modal/VResumeSubscriptionSuccessModal.vue?macro=true";
import { default as PersonalReferrerJCQDWhsKq9Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/PersonalReferrer.vue?macro=true";
import { default as validationXaK8hnHHLjMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/validation.ts?macro=true";
import { default as VBonusHistoryxL5zjQMaPGMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/VBonusHistory.vue?macro=true";
import { default as VNotificationCardNavYrRvMdLMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/VNotificationCard.vue?macro=true";
import { default as VNotificationsSettingskgs7q14MrYMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/VNotificationsSettings.vue?macro=true";
import { default as VPaymentsMcC6Fbfv3YMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/VPayments.vue?macro=true";
import { default as VProfileTabsTsA8Brld4DMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/VProfileTabs.vue?macro=true";
import { default as VReferralProgramChqGXAVX8JMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/VReferralProgram.vue?macro=true";
import { default as VSubscriptionsSIr2mTAzaWMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/VSubscriptions.vue?macro=true";
import { default as indexIXOA8eeD4QMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/devices/index.vue?macro=true";
import { default as indexVeQzlXpdsxMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/index.vue?macro=true";
import { default as index8aPT2FvB3ZMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/notifications/index.vue?macro=true";
import { default as indexVCgehKpBppMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/payments/index.vue?macro=true";
import { default as indexnJgJf13WEJMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/settings/index.vue?macro=true";
import { default as resultHYOAnzDufbMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/result.vue?macro=true";
import { default as _91slug_9371iMZno6UGMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/similar/[slug].vue?macro=true";
import { default as indexMz2oHIGHFcMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/sports/channels/index.vue?macro=true";
import { default as indexVpItzjtjLRMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/sports/index.vue?macro=true";
import { default as SectionAdszeyxV8YVUmMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/subscriptions/components/SectionAds.vue?macro=true";
import { default as SectionContentKuUGLXdGbtMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/subscriptions/components/SectionContent.vue?macro=true";
import { default as SectionDevices4eRCOxhDXHMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/subscriptions/components/SectionDevices.vue?macro=true";
import { default as SectionDownload43uueXjX5xMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/subscriptions/components/SectionDownload.vue?macro=true";
import { default as SectionHeaderz0x6IxyPcCMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/subscriptions/components/SectionHeader.vue?macro=true";
import { default as SectionSubscriptionsC2kNSirtYMMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/subscriptions/components/SectionSubscriptions.vue?macro=true";
import { default as SectionVoiceovermCYS7ixihjMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/subscriptions/components/SectionVoiceover.vue?macro=true";
import { default as indexXUIpsN2fr1Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/subscriptions/index.vue?macro=true";
import { default as termsVPbSbbkFNBMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/terms.vue?macro=true";
import { default as upcomingevo5ivfKywMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/upcoming.vue?macro=true";
export default [
  {
    name: "browse-category",
    path: "/browse/:category()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/browse/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "browse-components",
    path: "/browse/components",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/browse/components/index.ts").then(m => m.default || m)
  },
  {
    name: "browse-components-PageFilters",
    path: "/browse/components/PageFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/browse/components/PageFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionGenres",
    path: "/browse/components/SectionGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/browse/components/SectionGenres.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionMovies",
    path: "/browse/components/SectionMovies",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/browse/components/SectionMovies.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionPopular",
    path: "/browse/components/SectionPopular",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/browse/components/SectionPopular.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionSeries",
    path: "/browse/components/SectionSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/browse/components/SectionSeries.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SkeletonFilters",
    path: "/browse/components/SkeletonFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/browse/components/SkeletonFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SkeletonGenres",
    path: "/browse/components/SkeletonGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/browse/components/SkeletonGenres.vue").then(m => m.default || m)
  },
  {
    name: "browse-genre-slug",
    path: "/browse/genre/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/browse/genre/[slug].vue").then(m => m.default || m)
  },
  {
    name: "browse",
    path: "/browse",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/browse/index.vue").then(m => m.default || m)
  },
  {
    name: "collections-slug",
    path: "/collections/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/collections/[slug].vue").then(m => m.default || m)
  },
  {
    name: "collections",
    path: "/collections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/collections/index.vue").then(m => m.default || m)
  },
  {
    name: "copyright",
    path: "/copyright",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/copyright.vue").then(m => m.default || m)
  },
  {
    name: "crew-id",
    path: "/crew/:id()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/crew/[id].vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "favorites-all",
    path: "/favorites/all",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/favorites/all.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionBought",
    path: "/favorites/components/SectionBought",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/favorites/components/SectionBought.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionContinue",
    path: "/favorites/components/SectionContinue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/favorites/components/SectionContinue.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionFavorites",
    path: "/favorites/components/SectionFavorites",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/favorites/components/SectionFavorites.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionViewed",
    path: "/favorites/components/SectionViewed",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/favorites/components/SectionViewed.vue").then(m => m.default || m)
  },
  {
    name: "favorites-continue",
    path: "/favorites/continue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/favorites/continue.vue").then(m => m.default || m)
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "favorites-purchased",
    path: "/favorites/purchased",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/favorites/purchased.vue").then(m => m.default || m)
  },
  {
    name: "favorites-viewed",
    path: "/favorites/viewed",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/favorites/viewed.vue").then(m => m.default || m)
  },
  {
    name: "holder-policy",
    path: "/holder-policy",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/holder-policy.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionAdvantages",
    path: "/home/components/SectionAdvantages",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/components/SectionAdvantages.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionCollections",
    path: "/home/components/SectionCollections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/components/SectionCollections.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionContinue",
    path: "/home/components/SectionContinue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/components/SectionContinue.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionFilms",
    path: "/home/components/SectionFilms",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/components/SectionFilms.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionGenres",
    path: "/home/components/SectionGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/components/SectionGenres.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionHeader",
    path: "/home/components/SectionHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/components/SectionHeader.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionPopular",
    path: "/home/components/SectionPopular",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/components/SectionPopular.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionPremiers",
    path: "/home/components/SectionPremiers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/components/SectionPremiers.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSeries",
    path: "/home/components/SectionSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/components/SectionSeries.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSubscriptions",
    path: "/home/components/SectionSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/components/SectionSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSummarize",
    path: "/home/components/SectionSummarize",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/components/SectionSummarize.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionUpcoming",
    path: "/home/components/SectionUpcoming",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/components/SectionUpcoming.vue").then(m => m.default || m)
  },
  {
    name: "home-Index",
    path: "/home/Index",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/home/Index.vue").then(m => m.default || m)
  },
  {
    name: "install-slug",
    path: "/install/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/install/[slug].vue").then(m => m.default || m)
  },
  {
    name: "install-components-InfoInstall",
    path: "/install/components/InfoInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/install/components/InfoInstall.vue").then(m => m.default || m)
  },
  {
    name: "install-components-TitleInstall",
    path: "/install/components/TitleInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/install/components/TitleInstall.vue").then(m => m.default || m)
  },
  {
    name: "install-components-types",
    path: "/install/components/types",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/install/components/types.ts").then(m => m.default || m)
  },
  {
    name: "install-components-useInstall",
    path: "/install/components/useInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/install/components/useInstall.ts").then(m => m.default || m)
  },
  {
    name: "movie-slug-filmcrew",
    path: "/movie/:slug()/filmcrew",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/[slug]/filmcrew.vue").then(m => m.default || m)
  },
  {
    name: "movie-slug",
    path: "/movie/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieCollections",
    path: "/movie/components/MovieCollections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/components/MovieCollections.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieCrew",
    path: "/movie/components/MovieCrew",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/components/MovieCrew.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieDescription",
    path: "/movie/components/MovieDescription",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/components/MovieDescription.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeader",
    path: "/movie/components/MovieHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/components/MovieHeader.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeaderNotice",
    path: "/movie/components/MovieHeaderNotice",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/components/MovieHeaderNotice.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeaderSkeleton",
    path: "/movie/components/MovieHeaderSkeleton",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/components/MovieHeaderSkeleton.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MoviePlayButton",
    path: "/movie/components/MoviePlayButton",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/components/MoviePlayButton.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieRelated",
    path: "/movie/components/MovieRelated",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/components/MovieRelated.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieSeries",
    path: "/movie/components/MovieSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/components/MovieSeries.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieTrailers",
    path: "/movie/components/MovieTrailers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/components/MovieTrailers.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieWatchOptions",
    path: "/movie/components/MovieWatchOptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/movie/components/MovieWatchOptions.vue").then(m => m.default || m)
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "novelty-films",
    path: "/novelty-films",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/novelty-films.vue").then(m => m.default || m)
  },
  {
    name: "novelty-series",
    path: "/novelty-series",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/novelty-series.vue").then(m => m.default || m)
  },
  {
    name: "policy-agreement",
    path: "/policy-agreement",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/policy-agreement.vue").then(m => m.default || m)
  },
  {
    name: "policy-copyright",
    path: "/policy-copyright",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/policy-copyright.vue").then(m => m.default || m)
  },
  {
    name: "policy-holder",
    path: "/policy-holder",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/policy-holder.vue").then(m => m.default || m)
  },
  {
    name: "policy-private",
    path: "/policy-private",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/policy-private.vue").then(m => m.default || m)
  },
  {
    name: "policy-user",
    path: "/policy-user",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/policy-user.vue").then(m => m.default || m)
  },
  {
    name: "premiers",
    path: "/premiers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/premiers.vue").then(m => m.default || m)
  },
  {
    name: "private-policy",
    path: "/private-policy",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/private-policy.vue").then(m => m.default || m)
  },
  {
    name: "profile-bonuses",
    path: "/profile/bonuses",
    meta: indexsrGRZsZy6DMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/bonuses/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VChangeRecurrentCardModal",
    path: "/profile/components/modal/VChangeRecurrentCardModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/modal/VChangeRecurrentCardModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VEditProfile",
    path: "/profile/components/modal/VEditProfile",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/modal/VEditProfile.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VRejectSubscribeModal",
    path: "/profile/components/modal/VRejectSubscribeModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/modal/VRejectSubscribeModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VResumeSubscriptionSuccessModal",
    path: "/profile/components/modal/VResumeSubscriptionSuccessModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/modal/VResumeSubscriptionSuccessModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-PersonalReferrer",
    path: "/profile/components/PersonalReferrer",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/PersonalReferrer.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-validation",
    path: "/profile/components/validation",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/validation.ts").then(m => m.default || m)
  },
  {
    name: "profile-components-VBonusHistory",
    path: "/profile/components/VBonusHistory",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/VBonusHistory.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VNotificationCard",
    path: "/profile/components/VNotificationCard",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/VNotificationCard.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VNotificationsSettings",
    path: "/profile/components/VNotificationsSettings",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/VNotificationsSettings.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VPayments",
    path: "/profile/components/VPayments",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/VPayments.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VProfileTabs",
    path: "/profile/components/VProfileTabs",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/VProfileTabs.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VReferralProgram",
    path: "/profile/components/VReferralProgram",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/VReferralProgram.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VSubscriptions",
    path: "/profile/components/VSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/components/VSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "profile-devices",
    path: "/profile/devices",
    meta: indexIXOA8eeD4QMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/devices/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexVeQzlXpdsxMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-notifications",
    path: "/profile/notifications",
    meta: index8aPT2FvB3ZMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-payments",
    path: "/profile/payments",
    meta: indexVCgehKpBppMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings",
    path: "/profile/settings",
    meta: indexnJgJf13WEJMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/profile/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "result",
    path: "/result",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/result.vue").then(m => m.default || m)
  },
  {
    name: "similar-slug",
    path: "/similar/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/similar/[slug].vue").then(m => m.default || m)
  },
  {
    name: "sports-channels",
    path: "/sports/channels",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/sports/channels/index.vue").then(m => m.default || m)
  },
  {
    name: "sports",
    path: "/sports",
    meta: indexVpItzjtjLRMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/sports/index.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionAds",
    path: "/subscriptions/components/SectionAds",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/subscriptions/components/SectionAds.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionContent",
    path: "/subscriptions/components/SectionContent",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/subscriptions/components/SectionContent.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionDevices",
    path: "/subscriptions/components/SectionDevices",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/subscriptions/components/SectionDevices.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionDownload",
    path: "/subscriptions/components/SectionDownload",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/subscriptions/components/SectionDownload.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionHeader",
    path: "/subscriptions/components/SectionHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/subscriptions/components/SectionHeader.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionSubscriptions",
    path: "/subscriptions/components/SectionSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/subscriptions/components/SectionSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionVoiceover",
    path: "/subscriptions/components/SectionVoiceover",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/subscriptions/components/SectionVoiceover.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions",
    path: "/subscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "upcoming",
    path: "/upcoming",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-306850/src/pages/upcoming.vue").then(m => m.default || m)
  }
]